/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { RANDOM as t, getEpsilon as n } from "./common.js";
function r(t, n) {
  return t[0] = n[0], t[1] = n[1], t;
}
function o(t, n, r) {
  return t[0] = n, t[1] = r, t;
}
function u(t, n, r) {
  return t[0] = n[0] + r[0], t[1] = n[1] + r[1], t;
}
function e(t, n, r) {
  return t[0] = n[0] - r[0], t[1] = n[1] - r[1], t;
}
function c(t, n, r) {
  return t[0] = n[0] * r[0], t[1] = n[1] * r[1], t;
}
function a(t, n, r) {
  return t[0] = n[0] / r[0], t[1] = n[1] / r[1], t;
}
function s(t, n) {
  return t[0] = Math.ceil(n[0]), t[1] = Math.ceil(n[1]), t;
}
function i(t, n) {
  return t[0] = Math.floor(n[0]), t[1] = Math.floor(n[1]), t;
}
function f(t, n, r) {
  return t[0] = Math.min(n[0], r[0]), t[1] = Math.min(n[1], r[1]), t;
}
function M(t, n, r) {
  return t[0] = Math.max(n[0], r[0]), t[1] = Math.max(n[1], r[1]), t;
}
function h(t, n) {
  return t[0] = Math.round(n[0]), t[1] = Math.round(n[1]), t;
}
function l(t, n, r) {
  return t[0] = n[0] * r, t[1] = n[1] * r, t;
}
function d(t, n, r, o) {
  return t[0] = n[0] + r[0] * o, t[1] = n[1] + r[1] * o, t;
}
function m(t, n) {
  const r = n[0] - t[0],
    o = n[1] - t[1];
  return Math.sqrt(r * r + o * o);
}
function b(t, n) {
  const r = n[0] - t[0],
    o = n[1] - t[1];
  return r * r + o * o;
}
function q(t) {
  const n = t[0],
    r = t[1];
  return Math.sqrt(n * n + r * r);
}
function p(t) {
  const n = t[0],
    r = t[1];
  return n * n + r * r;
}
function x(t, n) {
  return t[0] = -n[0], t[1] = -n[1], t;
}
function g(t, n) {
  return t[0] = 1 / n[0], t[1] = 1 / n[1], t;
}
function v(t, n) {
  const r = n[0],
    o = n[1];
  let u = r * r + o * o;
  return u > 0 && (u = 1 / Math.sqrt(u), t[0] = n[0] * u, t[1] = n[1] * u), t;
}
function j(t, n) {
  return t[0] * n[0] + t[1] * n[1];
}
function y(t, n, r) {
  const o = n[0] * r[1] - n[1] * r[0];
  return t[0] = t[1] = 0, t[2] = o, t;
}
function _(t, n, r, o) {
  const u = n[0],
    e = n[1];
  return t[0] = u + o * (r[0] - u), t[1] = e + o * (r[1] - e), t;
}
function A(n, r = 1) {
  const o = 2 * t() * Math.PI;
  return n[0] = Math.cos(o) * r, n[1] = Math.sin(o) * r, n;
}
function P(t, n, r) {
  const o = n[0],
    u = n[1];
  return t[0] = r[0] * o + r[2] * u, t[1] = r[1] * o + r[3] * u, t;
}
function S(t, n, r) {
  const o = n[0],
    u = n[1];
  return t[0] = r[0] * o + r[2] * u + r[4], t[1] = r[1] * o + r[3] * u + r[5], t;
}
function z(t, n, r) {
  const o = n[0],
    u = n[1];
  return t[0] = r[0] * o + r[3] * u + r[6], t[1] = r[1] * o + r[4] * u + r[7], t;
}
function D(t, n, r) {
  const o = n[0],
    u = n[1];
  return t[0] = r[0] * o + r[4] * u + r[12], t[1] = r[1] * o + r[5] * u + r[13], t;
}
function I(t, n, r, o) {
  const u = n[0] - r[0],
    e = n[1] - r[1],
    c = Math.sin(o),
    a = Math.cos(o);
  return t[0] = u * a - e * c + r[0], t[1] = u * c + e * a + r[1], t;
}
function L(t, n) {
  const r = t[0],
    o = t[1],
    u = n[0],
    e = n[1];
  let c = r * r + o * o;
  c > 0 && (c = 1 / Math.sqrt(c));
  let a = u * u + e * e;
  a > 0 && (a = 1 / Math.sqrt(a));
  const s = (r * u + o * e) * c * a;
  return s > 1 ? 0 : s < -1 ? Math.PI : Math.acos(s);
}
function O(t) {
  return "vec2(" + t[0] + ", " + t[1] + ")";
}
function E(t, n) {
  return t[0] === n[0] && t[1] === n[1];
}
function T(t, r) {
  const o = t[0],
    u = t[1],
    e = r[0],
    c = r[1],
    a = n();
  return Math.abs(o - e) <= a * Math.max(1, Math.abs(o), Math.abs(e)) && Math.abs(u - c) <= a * Math.max(1, Math.abs(u), Math.abs(c));
}
function k(t, n, r, o, u) {
  let e = n[0] - r[0],
    c = n[1] - r[1];
  const a = (o[0] * e + o[1] * c) * (u - 1);
  return e = o[0] * a, c = o[1] * a, t[0] = n[0] + e, t[1] = n[1] + c, t;
}
const w = q,
  B = e,
  C = c,
  F = a,
  G = m,
  H = b,
  J = p,
  K = Object.freeze(Object.defineProperty({
    __proto__: null,
    add: u,
    angle: L,
    ceil: s,
    copy: r,
    cross: y,
    dist: G,
    distance: m,
    div: F,
    divide: a,
    dot: j,
    equals: T,
    exactEquals: E,
    floor: i,
    inverse: g,
    len: w,
    length: q,
    lerp: _,
    max: M,
    min: f,
    mul: C,
    multiply: c,
    negate: x,
    normalize: v,
    projectAndScale: k,
    random: A,
    rotate: I,
    round: h,
    scale: l,
    scaleAndAdd: d,
    set: o,
    sqrDist: H,
    sqrLen: J,
    squaredDistance: b,
    squaredLength: p,
    str: O,
    sub: B,
    subtract: e,
    transformMat2: P,
    transformMat2d: S,
    transformMat3: z,
    transformMat4: D
  }, Symbol.toStringTag, {
    value: "Module"
  }));
export { u as add, L as angle, s as ceil, r as copy, y as cross, G as dist, m as distance, F as div, a as divide, j as dot, T as equals, E as exactEquals, i as floor, g as inverse, w as len, q as length, _ as lerp, M as max, f as min, C as mul, c as multiply, x as negate, v as normalize, k as projectAndScale, A as random, I as rotate, h as round, l as scale, d as scaleAndAdd, o as set, H as sqrDist, J as sqrLen, b as squaredDistance, p as squaredLength, O as str, B as sub, e as subtract, P as transformMat2, S as transformMat2d, z as transformMat3, D as transformMat4, K as v };